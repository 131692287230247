import React, { useCallback, useEffect, useRef } from 'react';
import { OnEvent, WidgetEventSignal } from '../enums';
import { Link } from '../link';
import { RenderOptions } from '../types';

export interface PalencaReactProps {
  /** Palenca Link instance */
  link: Promise<Link>;
  /** Palenca Link render options */
  options?: RenderOptions;
  /** Palenca Link on event callback */
  onEvent?: (event: WidgetEventSignal, data: object) => void;
}

export const PalencaLinkReact = ({
  link,
  options,
  onEvent,
  ...any
}: PalencaReactProps) => {
  const shouldRender = useRef(true);
  const palencaLink = useRef<Link | null>(null);

  if (!link) return null;

  const onEventHandler = useCallback(
    (data: object, event: WidgetEventSignal) => {
      if (event && onEvent) {
        onEvent(event, data);
      }
    },
    []
  );

  const render = useCallback(() => {
    if (!link) return;
    link
      .then(link => {
        palencaLink.current = link;
        link.on(OnEvent.ready, onEventHandler);
        link.on(OnEvent.connection_success, onEventHandler);
        link.on(OnEvent.connection_error, onEventHandler);
        link.on(OnEvent.user_created, onEventHandler);
        link.render('palenca-link', options || {});
      })
      .catch(error => {
        console.error(error);
      });
  }, [link, options, onEventHandler]);

  useEffect(() => {
    if (shouldRender.current) {
      shouldRender.current = false;
      render();
    }
    return () => {
      palencaLink.current?.destroy();
    };
  }, []);

  return (
    <div
      id="palenca-link"
      style={{
        width: '100%',
        height: '100%',
      }}
      {...any}
    />
  );
};
